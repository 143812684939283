:root {
  /** primary BlueA400 **/
  --ion-color-primary: #2979ff;
  --ion-color-primary-rgb: 41, 121, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #246ae0;
  --ion-color-primary-tint: #3e86ff;

  /** secondary LightBlueA400 **/
  --ion-color-secondary: #00b0ff;
  --ion-color-secondary-rgb: 0, 176, 255;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #009be0;
  --ion-color-secondary-tint: #1ab8ff;

  /** danger RedA200 **/
  --ion-color-danger: #ff5252;
  --ion-color-danger-rgb: 255, 82, 82;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e04848;
  --ion-color-danger-tint: #ff6363;

  /** dark Grey900 **/
  --ion-color-dark: #212121;
  --ion-color-dark-rgb: 33, 33, 33;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1d1d1d;
  --ion-color-dark-tint: #373737;

  /** medium Grey600 **/
  --ion-color-medium: #757575;
  --ion-color-medium-rgb: 117, 117, 117;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #676767;
  --ion-color-medium-tint: #838383;

  /** light Grey300 **/
  --ion-color-light: #e0e0e0;
  --ion-color-light-rgb: 224, 224, 224;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #c5c5c5;
  --ion-color-light-tint: #e3e3e3;
}

/** utilities **/
.app-select-popover-no-padding ion-list {
  padding: 0;
}

.app-loading {
  --background: none;
  --spinner-color: white;
}

.app-loading .loading-wrapper {
  box-shadow: none;
}

.app-popover-width40 {
  --min-width: 40px;
  --max-width: 40px;
  --width: 40px;
}

.app-popover-width80 {
  --min-width: 80px;
  --max-width: 80px;
  --width: 80px;
}

.app-popover-width120 {
  --min-width: 120px;
  --max-width: 120px;
  --width: 120px;
}

.app-popover-width160 {
  --min-width: 160px;
  --max-width: 160px;
  --width: 160px;
}

.app-popover-width200 {
  --min-width: 200px;
  --max-width: 200px;
  --width: 200px;
}

.app-popover-width240 {
  --min-width: 240px;
  --max-width: 240px;
  --width: 240px;
}

.app-popover-width280 {
  --min-width: 280px;
  --max-width: 280px;
  --width: 280px;
}

.app-popover-width320 {
  --min-width: 320px;
  --max-width: 320px;
  --width: 320px;
}

.app-popover-width360 {
  --min-width: 360px;
  --max-width: 360px;
  --width: 360px;
}

.app-popover-width400 {
  --min-width: 400px;
  --max-width: 400px;
  --width: 400px;
}

.app-popover-height40 {
  --max-height: 40px;
  --min-height: 40px;
  --height: 40px;
}

.app-popover-height80 {
  --max-height: 80px;
  --min-height: 80px;
  --height: 80px;
}

.app-popover-height120 {
  --max-height: 120px;
  --min-height: 120px;
  --height: 120px;
}

.app-popover-height160 {
  --max-height: 160px;
  --min-height: 160px;
  --height: 160px;
}

.app-popover-height200 {
  --max-height: 200px;
  --min-height: 200px;
  --height: 200px;
}

.app-popover-height240 {
  --max-height: 240px;
  --min-height: 240px;
  --height: 240px;
}

.app-popover-height280 {
  --max-height: 280px;
  --min-height: 280px;
  --height: 280px;
}

.app-popover-height320 {
  --max-height: 320px;
  --min-height: 320px;
  --height: 320px;
}

.app-popover-height360 {
  --max-height: 360px;
  --min-height: 360px;
  --height: 360px;
}

.app-popover-height400 {
  --max-height: 400px;
  --min-height: 400px;
  --height: 400px;
}

.app-popover-min-height40 {
  --min-height: 40px;
}

.app-popover-min-height80 {
  --min-height: 80px;
}

.app-popover-min-height120 {
  --min-height: 120px;
}

.app-popover-min-height160 {
  --min-height: 160px;
}

.app-popover-min-height200 {
  --min-height: 200px;
}

.app-popover-min-height240 {
  --min-height: 240px;
}

.app-popover-min-height280 {
  --min-height: 280px;
}

.app-popover-min-height320 {
  --min-height: 320px;
}

.app-popover-min-height360 {
  --min-height: 360px;
}

.app-popover-min-height400 {
  --min-height: 400px;
}

.app-alert-pre-wrap {
  white-space: pre-wrap;
}

:root {
  /* --ion-font-family: "Hiragino Sans", "Meiryo", -apple-system,
    BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif; */

  /* colors */
  --app-color-white: #ffffff;
  --app-color-black: #000000;

  --app-color-primary50: #e3f2fd;
  --app-color-primary100: #bbdefb;
  --app-color-primary200: #90caf9;
  --app-color-primary300: #64b5f6;
  --app-color-primary400: #42a5f5;
  --app-color-primary500: #2196f3;
  --app-color-primary600: #1e88e5;
  --app-color-primary700: #1976d2;
  --app-color-primary800: #1565c0;
  --app-color-primary900: #0d47a1;

  --app-color-grey50: #fafafa;
  --app-color-grey100: #f5f5f5;
  --app-color-grey200: #eeeeee;
  --app-color-grey300: #e0e0e0;
  --app-color-grey400: #bdbdbd;
  --app-color-grey500: #9e9e9e;
  --app-color-grey600: #757575;
  --app-color-grey700: #616161;
  --app-color-grey800: #424242;
  --app-color-grey900: #212121;

  --app-color-grey50-rgba: rgba(0, 0, 0, 0.02);
  --app-color-grey100-rgba: rgba(0, 0, 0, 0.03);
  --app-color-grey200-rgba: rgba(0, 0, 0, 0.06);
  --app-color-grey300-rgba: rgba(0, 0, 0, 0.11);
  --app-color-grey400-rgba: rgba(0, 0, 0, 0.23);
  --app-color-grey500-rgba: rgba(0, 0, 0, 0.35);
  --app-color-grey600-rgba: rgba(0, 0, 0, 0.51);
  --app-color-grey700-rgba: rgba(0, 0, 0, 0.59);
  --app-color-grey800-rgba: rgba(0, 0, 0, 0.72);
  --app-color-grey900-rgba: rgba(0, 0, 0, 0.87);

  --app-color-blue-grey50: #eceff1;
  --app-color-blue-grey100: #cfd8dc;
  --app-color-blue-grey200: #b0bec5;
  --app-color-blue-grey300: #90a4ae;
  --app-color-blue-grey400: #78909c;
  --app-color-blue-grey500: #607d8b;
  --app-color-blue-grey600: #546e7a;
  --app-color-blue-grey700: #455a64;
  --app-color-blue-grey800: #37474f;
  --app-color-blue-grey900: #263238;

  --app-color-white50-rgba: rgba(255, 255, 255, 0.05);
  --app-color-white100-rgba: rgba(255, 255, 255, 0.1);
  --app-color-white200-rgba: rgba(255, 255, 255, 0.2);
  --app-color-white300-rgba: rgba(255, 255, 255, 0.3);
  --app-color-white400-rgba: rgba(255, 255, 255, 0.4);
  --app-color-white500-rgba: rgba(255, 255, 255, 0.5);
  --app-color-white600-rgba: rgba(255, 255, 255, 0.6);
  --app-color-white700-rgba: rgba(255, 255, 255, 0.7);
  --app-color-white800-rgba: rgba(255, 255, 255, 0.8);
  --app-color-white900-rgba: rgba(255, 255, 255, 0.9);

  --app-color-notification: #ff5252; /* RedA200 */
  --app-color-notification-background: #ffcdd2; /* Red100 */

  --ion-item-border-color: var(--app-color-grey300);
  --ion-border-color: var(--app-color-grey300);
  --ion-tab-bar-border-color: var(--app-color-grey300);
  --ion-toolbar-border-color: var(--app-color-grey300);
}

:root {
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

:root {
  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

/* indigo 800 x black  */
:root {
  --ion-color-navy: #141b4a;
  --ion-color-navy-rgb: 20, 27, 74;
  --ion-color-navy-contrast: #ffffff;
  --ion-color-navy-contrast-rgb: 255, 255, 255;
  --ion-color-navy-shade: #121841;
  --ion-color-navy-tint: #2c325c;
}

.ion-color-navy {
  --ion-color-base: var(--ion-color-navy);
  --ion-color-base-rgb: var(--ion-color-navy-rgb);
  --ion-color-contrast: var(--ion-color-navy-contrast);
  --ion-color-contrast-rgb: var(--ion-color-navy-contrast-rgb);
  --ion-color-shade: var(--ion-color-navy-shade);
  --ion-color-tint: var(--ion-color-navy-tint);
}

/* shadow */
:root {
  --app-shadow-0dp: none;
  --app-shadow-1dp: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  --app-shadow-2dp: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  --app-shadow-3dp: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  --app-shadow-4dp: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --app-shadow-6dp: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  --app-shadow-8dp: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  --app-shadow-9dp: 0 9px 12px 1px rgba(0, 0, 0, 0.14),
    0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.2);
  --app-shadow-12dp: 0 12px 17px 2px rgba(0, 0, 0, 0.14),
    0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
  --app-shadow-16dp: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  --app-shadow-24dp: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

/* animation */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
