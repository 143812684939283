.editor-text-bold {
  font-weight: bold;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

[data-lexical-text] {
  font-size: 16px;
}
